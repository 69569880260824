<template>
  <div class="WebCastOthers">
    <div :class="{ 'content-margin': this.$screen.width >= 1024 }">
      <div class="poligon-title">
        <h3>Otros</h3>
      </div>
    </div>
    <VueSlickCarouselc :lazyLoad="'progressive'" class="WebCastOthers__carousel content-margin" v-bind="settings">
      <div v-for="(slide, i) in slides" class="WebCastOthers__carousel-slide" :key="i" data-aos="fade-up" @click="openYoutubeModal">
        <img :src="slide.media" alt="Icono slide carrousel" />
      </div>
    </VueSlickCarouselc>
    <postModal ref="postModal" />
  </div>
</template>

<script>
import VueSlickCarouselc from "vue-slick-carousel";
import postModal from "../modals/PostModal.vue";
export default {
  components: { VueSlickCarouselc, postModal },
  data: function () {
    return {
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 2.1,
              dots: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      slides: [
        {
          media: "https://new-web-site-bucket.s3.amazonaws.com/dev/webcast/tamara.png",
        },
        {
          media: "https://new-web-site-bucket.s3.amazonaws.com/dev/webcast/roa.png",
        },
        {
          media: "https://new-web-site-bucket.s3.amazonaws.com/dev/webcast/roa-car.png",
        },
        {
          media: "https://new-web-site-bucket.s3.amazonaws.com/dev/webcast/paracaidas.png",
        },
        {
          media: "https://new-web-site-bucket.s3.amazonaws.com/dev/webcast/glases.png",
        },
      ],
    };
  },
  methods: {
    openYoutubeModal() {
      this.$refs.postModal.openYoutubeModal();
    },
  },
};
</script>

<style lang="scss">
.WebCastOthers {
  margin-top: 50px;
  &__title {
    padding: 20px;
    color: #fff;
    background: $primary_color;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }
  &__carousel {
    &-slide {
      position: relative;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 12px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 90.16%, rgba(0, 0, 0, 0.7) 100%);
      }

      img {
        border-radius: 15px;
        width: 100%;
      }
      h4 {
        position: absolute;
        bottom: 0;
        color: #fff;
        text-align: center;
        width: 100%;
        z-index: 20;
        padding: 20px;
        font-size: 15px;
        text-transform: uppercase;
      }
    }
    .slick-track {
      display: flex;
      justify-content: space-between;
      gap: 0 15px;
    }
  }

  @include Desktop {
  }
}
</style>
